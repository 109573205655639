<template>
  <div>
    <b-card>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: nik -->
          <span v-if="props.column.field === 'nik'" class="text-nowrap">
            <span v-if="props.row.nik === null">-</span>
            <span v-else>{{ props.row.nik }}</span>
          </span>

          <!-- Column: Name -->
          <span v-else-if="props.column.field === 'name'" class="text-nowrap">
            <span class="text-nowrap">{{ props.row.name }}</span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ statusName(props.row.status) }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="onEdit(props.row)">
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>Detail</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="onUpload(props.row)"
                  v-if="props.row.status == 0"
                >
                  <feather-icon icon="UploadIcon" class="mr-50" />
                  <span>Upload</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="onSend(props.row)"
                  v-if="props.row.status == 1"
                >
                  <feather-icon icon="SendIcon" class="mr-50" />
                  <span>Kirim</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="onClaim(props.row)"
                  v-if="props.row.status == 3"
                >
                  <feather-icon icon="FileTextIcon" class="mr-50" />
                  <span>Klaim</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <div @click="fabAction">
      <vue-fab mainBtnColor="#3867d6" size="big" :scrollAutoHide="false">
      </vue-fab>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          field: "policy_number",
          label: "No Polis",
          sortable: true,
          filterable: true,
        },
        { field: "nik", label: "NIK", sortable: false, filterable: true },
        { field: "name", label: "Nama", sortable: true, filterable: true },
        {
          field: "phone",
          label: "No Telepon",
          sortable: false,
          filterable: true,
        },
        { field: "start_date", label: "Tanggal Mulai", sortable: true },
        { field: "end_date", label: "Tanggal Berkahir", sortable: true },
        { field: "status", label: "Status", sortable: true },
        { field: "action", label: "Action", sortable: false, filterable: true },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        0: "light-secondary",
        1: "light-primary",
        2: "light-primary",
        3: "light-success",
        4: "light-warning",
      };

      return (status) => statusColor[status];
    },
    statusName() {
      const statusName = {
        0: "Menunggu pembayaran",
        1: "Siap dikirim",
        2: "Sedang diproses",
        3: "Aktif",
        4: "Non-aktif",
      };

      return (status) => statusName[status];
    },
  },
  created() {
    this.$http.get("/policy").then((res) => {
      this.rows = res.data.data;
    });
  },
  methods: {
    fabAction: function () {
      this.$router.push("/policy/add");
    },
    onClaim($item) {
      this.$router.push({
        name: "claim-add",
        params: { id: $item.id },
      });
    },
    onEdit($item) {
      this.$router.push({
        name: "policy",
        params: { id: $item.id },
      });
    },
    onUpload($item) {
      console.log("upload " + $item);
    },
    onSend($item) {
      console.log("send " + $item);
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
